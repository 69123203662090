import React from 'react'

import { useForm, ValidationError } from '@formspree/react';


function ContactForm() {
  const [state, handleSubmit] = useForm("meqbbbzl");
  if (state.succeeded) {
      return <p>Thanks for the idea!</p>;
  }
  return (


<form class="form-horizontal" onSubmit={handleSubmit}>
<fieldset>

<legend>Ted Williams for Assembly - District 2 Policy Ideas</legend>

<div class="form-group">
  <label class="col-md-4 control-label" for="town">Town</label>  
  <div class="col-md-4">
  <input id="town" name="town" type="text" placeholder="your town" class="form-control input-md"/>
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="name">Name</label>  
  <div class="col-md-4">
  <input id="name" name="name" type="text" placeholder="your name" class="form-control input-md"/>
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="email">Email</label>  
  <div class="col-md-4">
  <input id="email" name="email" type="text" placeholder="your email" class="form-control input-md"/>
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="phone">Phone</label>  
  <div class="col-md-4">
  <input id="phone" name="phone" type="text" placeholder="phone" class="form-control input-md"/>
    
  </div>
</div>

<div class="form-group">
  <label class="col-md-4 control-label" for="idea">Policy Idea</label>
  <div class="col-md-4">                     
    <textarea class="form-control" id="idea" name="idea">your policy idea</textarea>
  </div>
</div>

      <button type="submit" disabled={state.submitting}> Submit </button>
</fieldset>
</form>

  );
}


export const Ideas = (props) => {
  return (
    <div id='ideas' className='text-center'>
      <div className='container'>
        <ContactForm/>

    </div>
    </div>
  )
}
