import React from 'react'
import ReactDOM from 'react-dom'

import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Contact } from "./components/contact";
import { Ideas } from "./components/ideas";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";

import "./App.css";

import ReactGA from 'react-ga4';


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});





    ReactGA.initialize('G-4VW1VKH4CC');

  const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);


  return (
    <div>
      <Header data={landingPageData.Header} />
      <Ideas data={landingPageData.Ideas} />

    </div>
  );
};

export default App;
